import React from 'react'
import Layout from '../components/layout/layout'
import SeasonsPage from '../components/partials/SeasonsPage'


const Seasons = () =>{
  return(
    <Layout page="seasons">
      <SeasonsPage />
    </Layout>
  )
}

export default Seasons