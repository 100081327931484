import React from "react";
import "./seasonspage.scss"

const SeasonsPage = () => {
  return (
    <div className="seasons-container overflow-hidden relative pb-12 pt-32  xl:pb-32 md:px-24 font-bebas">
      <p className="text-center text-5xl lg:text-8xl uppercase text-app-purple-100 font-normal lg:mt-8">seasons</p>
      <div className="relative my-8 lg:my-12 xl:my-12 sm:block hidden container mx-auto">
        <img src="/seasons_page/line_pc_total.png" alt="line" className="col-start-1 col-end-8 row-start-2 row-end-6" />
        <div className="grid grid-cols-7 grid-rows-6 absolute top-0 bottom-0 left-0 right-0">
          <div className="col-start-1 row-start-3 flex justify-center items-end">
            <img src="/seasons_page/blue.png" className="w-8 lg:w-10 xl:w-12 transform" alt="active" />
          </div>
          <div className=" col-start-2 row-start-4 relative flex items-center justify-end">
            <img src="/seasons_page/white.svg" className="w-6 lg:w-8 xl:w-10 transform -translate-x-1/2 translate-y-2" alt="inactive" />
          </div>
          <div className=" col-start-4 row-start-5 relative flex items-end justify-start">
            <img src="/seasons_page/white.svg" className="w-6 lg:w-8 xl:w-10 transform -translate-x-1/4 translate-y-1/4" alt="inactive" />
          </div>
          <div className=" col-start-4 row-start-4 relative flex items-start justify-center">
            <img src="/seasons_page/white.svg" className="w-6 lg:w-8 xl:w-10 transform translate-x-1/3 translate-y-2" alt="inactive" />
          </div>
          <div className="col-start-5 row-start-2 relative flex items-center justify-center">
            <img src="/seasons_page/white.svg" className="w-6 lg:w-8 xl:w-10 transform -translate-x-full " alt="inactive" />
          </div>
          <div className="col-start-6 row-start-3 relative flex items-end justify-start">
            <img src="/seasons_page/white.svg" className="w-6 lg:w-8 xl:w-10 transform -translate-y-1/3" alt="inactive" />
          </div>
          <div className="col-start-6 row-start-4 relative flex items-end justify-end">
            <img src="/seasons_page/white.svg" className="w-6 lg:w-8 xl:w-10 transform -translate-y-1/3" alt="inactive" />
          </div>
          <div className="col-start-7 row-start-3 relative flex items-center justify-center">
            <img src="/seasons_page/white.svg" className="w-6 lg:w-8 xl:w-10 transform translate-x-1/2" alt="inactive" />
          </div>

          <div className="uppercase whitespace-nowrap col-start-1 row-start-3 relative">
            <div className="absolute flex flex-col bottom-1/2 -left-2">
              <p className="leading-4 lg:text-4xl xl:text-5xl text-white">SEASON 1</p>
                <div className="flex items-center">
                  <p className="leading-4 lg:text-2xl xl:text-4xl text-app-purple-100 mr-2 lg:mr-4">Artist: riotg3ar</p>
                  <a href="https://twitter.com/RIOTG3AR" target="_blank" rel="noreferrer">
                    <img src="/icons/ic_twitter_purple.svg" alt="social icon" className="w-4 lg:w-6 xl:w-8 -mt-1"/>
                  </a>
                </div>
              <p className="leading-4 lg:text-2xl xl:text-4xl text-app-purple-100">theme: norse mythology</p>
            </div>
          </div>
          <div className="uppercase whitespace-nowrap col-start-2 row-start-3 relative">
            <div className="absolute flex flex-col bottom-0 left-2/3">
              <p className="leading-4 lg:text-3xl xl:text-5xl text-white">SEASON 2</p>
              <p className="leading-4 lg:text-2xl xl:text-4xl text-app-purple-100">coming soon</p>
            </div>
          </div>
          <div className="uppercase whitespace-nowrap col-start-3 row-start-6 relative">
            <div className="absolute flex flex-col bottom-0 transform translate-y-3 left-1/2">
              <p className="leading-4 lg:text-3xl xl:text-5xl text-white">SEASON 3</p>
              <p className="leading-4 lg:text-2xl xl:text-4xl text-app-purple-100">coming soon</p>
            </div>
          </div>
          <div className="uppercase whitespace-nowrap col-start-4 row-start-5 relative">
            <div className="absolute flex flex-col top-0 transform left-1/2">
              <p className="leading-4 lg:text-3xl xl:text-5xl text-white">SEASON 4</p>
              <p className="leading-4 lg:text-2xl xl:text-4xl text-app-purple-100">coming soon</p>
            </div>
          </div>
          <div className="uppercase whitespace-nowrap col-start-5 row-start-1 relative">
            <div className="absolute flex flex-col transform bottom-0 -translate-x-1/2 left-0">
              <p className="leading-4 lg:text-3xl xl:text-5xl text-white">SEASON 5</p>
              <p className="leading-4 lg:text-2xl xl:text-4xl text-app-purple-100">coming soon</p>
            </div>
          </div>
          <div className="uppercase whitespace-nowrap col-start-6 row-start-2 relative">
            <div className="absolute flex flex-col bottom-0 transform left-2">
              <p className="leading-4 lg:text-3xl xl:text-5xl text-white">SEASON 6</p>
              <p className="leading-4 lg:text-2xl xl:text-4xl text-app-purple-100">coming soon</p>
            </div>
          </div>
          <div className="uppercase whitespace-nowrap col-start-6 row-start-5 relative">
            <div className="absolute flex flex-col top-0 transform left-10">
              <p className="leading-4 lg:text-3xl xl:text-5xl text-white">SEASON 7</p>
              <p className="leading-4 lg:text-2xl xl:text-4xl text-app-purple-100">coming soon</p>
            </div>
          </div>
          <div className="uppercase whitespace-nowrap col-start-7 row-start-2 relative">
            <div className="absolute flex flex-col bottom-0 transform left-1/3 xl:-right-10">
              <p className="leading-4 lg:text-3xl xl:text-5xl text-white">SEASON 8</p>
              <p className="leading-4 lg:text-2xl xl:text-4xl text-app-purple-100">coming soon</p>
            </div>
          </div>
        </div>
      </div>
      <div className="relative sm:hidden flex flex-col px-4 mt-8">
        <div className=" flex flex-col gap-2 relative">
        <img src="/seasons_page/line_ph.png" alt="phoneline" className="absolute top-12 h-full bottom-0 left-1/3 tiny:left-1/2 transform translate-x-8 tiny:translate-x-5" style={{height:"90%"}} />
          <div className="flex justify-between gap-4">
            <img src="/seasons_page/map_1.png" alt="map" className="w-1/3" />
            <div className="flex items-center gap-2 tiny:w-1/2 w-2/3 relative overflow-y-hidden">
              <img src="/seasons_page/blue.png" alt="active" className="w-10" />
              <div className="flex flex-col">
                <p className="leading-7 text-4xl text-white">SEASON 1</p>
                <div className="flex items-center">
                  <p className="leading-7 text-2xl text-app-purple-100 mr-2">Artist: riotg3ar</p>
                  <a href="https://twitter.com/RIOTG3AR" target="_blank" rel="noreferrer">
                    <img src="/icons/ic_twitter_purple.svg" alt="social icon" className="w-6 -mt-1"/>
                  </a>
                </div>
                <p className="leading-7 text-2xl text-app-purple-100">theme: norse mythology</p>
              </div>
            </div>
          </div>
          <div className="flex justify-between gap-4">
            <img src="/seasons_page/map_2.png" alt="map" className="w-1/3" />
            <div className="flex items-center gap-2 tiny:w-1/2 w-2/3 relative overflow-y-hidden">
              <img src="/seasons_page/white.svg" alt="inactive" className="w-8 ml-1" />
              <div className="flex flex-col">
                <p className="leading-7 text-4xl text-white">SEASON 2</p>
                <p className="leading-7 text-2xl text-app-purple-100">coming soon</p>
              </div>
            </div>
          </div>
          <div className="flex justify-between gap-4">
            <img src="/seasons_page/map_3.png" alt="map" className="w-1/3" />
            <div className="flex items-center gap-2 tiny:w-1/2 w-2/3 relative overflow-y-hidden">
              <img src="/seasons_page/white.svg" alt="inactive" className="w-8 ml-1" />
              <div className="flex flex-col">
                <p className="leading-7 text-4xl text-white">SEASON 3</p>
                <p className="leading-7 text-2xl text-app-purple-100">coming soon</p>
              </div>
            </div>
          </div>
          <div className="flex justify-between gap-4">
            <img src="/seasons_page/map_4.png" alt="map" className="w-1/3" />
            <div className="flex items-center gap-2 tiny:w-1/2 w-2/3 relative overflow-y-hidden">
              <img src="/seasons_page/white.svg" alt="inactive" className="w-8 ml-1" />
              <div className="flex flex-col">
                <p className="leading-7 text-4xl text-white">SEASON 4</p>
                <p className="leading-7 text-2xl text-app-purple-100">coming soon</p>
              </div>
            </div>
          </div>
          <div className="flex justify-between gap-4">
            <img src="/seasons_page/map_5.png" alt="map" className="w-1/3" />
            <div className="flex items-center gap-2 tiny:w-1/2 w-2/3 relative overflow-y-hidden">
              <img src="/seasons_page/white.svg" alt="inactive" className="w-8 ml-1" />
              <div className="flex flex-col">
                <p className="leading-7 text-4xl text-white">SEASON 5</p>
                <p className="leading-7 text-2xl text-app-purple-100">coming soon</p>
              </div>
            </div>
          </div>
          <div className="flex justify-between gap-4">
            <img src="/seasons_page/map_6.png" alt="map" className="w-1/3" />
            <div className="flex items-center gap-2 tiny:w-1/2 w-2/3 relative overflow-y-hidden">
              <img src="/seasons_page/white.svg" alt="inactive" className="w-8 ml-1" />
              <div className="flex flex-col">
                <p className="leading-7 text-4xl text-white">SEASON 6</p>
                <p className="leading-7 text-2xl text-app-purple-100">coming soon</p>
              </div>
            </div>
          </div>
          <div className="flex justify-between gap-4">
            <img src="/seasons_page/map_7.png" alt="map" className="w-1/3" />
            <div className="flex items-center gap-2 tiny:w-1/2 w-2/3 relative overflow-y-hidden">
              <img src="/seasons_page/white.svg" alt="inactive" className="w-8 ml-1" />
              <div className="flex flex-col">
                <p className="leading-7 text-4xl text-white">SEASON 7</p>
                <p className="leading-7 text-2xl text-app-purple-100">coming soon</p>
              </div>
            </div>
          </div>
          <div className="flex justify-between gap-4">
            <img src="/seasons_page/map_8.png" alt="map" className="w-1/3" />
            <div className="flex items-center gap-2 tiny:w-1/2 w-2/3 relative overflow-y-hidden">
              <img src="/seasons_page/white.svg" alt="inactive" className="w-8 ml-1" />
              <div className="flex flex-col">
                <p className="leading-7 text-4xl text-white">SEASON 8</p>
                <p className="leading-7 text-2xl text-app-purple-100">coming soon</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default SeasonsPage